.Pagination {
  padding: 13px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  background-color: var(--color-5);
  border-bottom: 3px solid var(--color-4);
}

.Pagination p {
  font-size: var(--font-size-md);
}

.select {
  height: 24px;
  display: flex;
  color: var(--font-color-3);
  background-color: var(--color-5);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.select:focus {
  outline: 1px solid red;
}

.select :global(.select__control) {
  box-shadow: none;
  padding: 0 5px;
  height: 24px;
  min-height: auto;
  display: flex;
  align-items: center;
  border-radius: 0;
  background-color: var(--color-12);
  border: none;
}

.select :global(.select__value-container) {
  margin: 0;
  padding: 0;
  width: 20px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select :global(.select__single-value) {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--font-color-4);
}

.select :global(.select__input-container) {
  position: absolute;
  opacity: 0;
}

.select :global(.select__input-container) input {
  position: absolute;
  opacity: 0;
  outline: none;
}

.select :global(.select__indicator) {
  padding: 0;
  margin: 0;
  width: 12px;
  color: var(--color-5);
  cursor: pointer;
}

.select :global(.select__indicators):hover {
  padding: 0;
  margin: 0;
  color: var(--color-8);
  width: 12px;
}

.select :global(.select__indicator-separator) {
  display: none;
}

.select :global(.select__menu) {
  right: 0;
  border-radius: 0;
  width: 200%;
  background-color: var(--color-12);
  box-shadow: none;
}

.select :global(.select__menu-list) {
  border-radius: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.select :global(.select__option) {
  height: 24px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-2);
  background-color: var(--color-12);
  cursor: pointer;
  transition: all var(--transition-speed-fast) ease;
}
.select :global(.select__option):hover {
  background-color: var(--color-11);
}

.select :global(.select__option):focus {
  background-color: var(--color-15);
}

.site {
  min-width: 90px;
  text-align: center;
}
