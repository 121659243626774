.UnprotectedView {
  position: relative;
}

.switcher {
  position: absolute;
  top: 20px;
  right: 20px;
}

.authors {
  width: 100%;
  position: absolute;
  bottom: 0;
}
