.ScrollableWrapper {
  width: 100%;
  overflow-y: auto;
  padding-right: 10px;
  padding-bottom: 10px;
}

.ScrollableWrapper::-webkit-scrollbar {
  right: 2px;
  width: 6px;
  height: 6px;
}

.ScrollableWrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  display: none;
}

.ScrollableWrapper::-webkit-scrollbar-thumb {
  background-color: var(--color-15);
  border-radius: 20px;
}

.ScrollableWrapper::-webkit-scrollbar-corner {
  display: none;
}
